<template>
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">Lista de Compras</div>
    <div class="text-500 mb-5">Adicione suas compras para gerenciar o estoque da sua clínica.</div>
    <Steps :model="items" :readonly="true" exact>
      <template #item="{item}">
        <router-link :to="item.to" custom >
          <ul class="list-none p-0 m-0 flex flex-column md:flex-row">
            <li class="relative mr-0 md:mr-8 flex-auto" v-if="item.id === 1">
              <div class="border-1 border-300 border-round p-3 surface-card flex flex-column md:flex-row align-items-center z-1">
                <i class="pi pi-shopping-cart text-cyan-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
                <div>
                  <div class="text-900 font-medium mb-1">Compras</div>
                  <span class="text-600 text-sm hidden md:block">Crie uma lista de compra</span>
                </div>
              </div>
              <div class="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px"></div>
            </li>
            <li class="relative mr-0 flex-auto" v-if="item.id === 2">
              <div class="border-2 border-blue-500 border-round p-3 surface-card flex flex-column md:flex-row align-items-center z-1">
                <i class="pi pi-list text-blue-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
                <div>
                  <div class="text-blue-600 font-medium mb-1">Produtos</div>
                  <span class="text-600 text-sm hidden md:block">Adicione todos os produtos comprados</span>
                </div>
              </div>
              <div class="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px"></div>
            </li>
            <li class="relative flex-auto" v-if="item.id === 3">
              <div class="border-1 border-300 border-round p-3 surface-50 flex flex-column md:flex-row align-items-center z-1">
                <i class="pi pi-check-circle text-green-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
                <div>
                  <div class="text-900 font-medium mb-1">Resumo</div>
                  <span class="text-600 text-sm hidden md:block">Visualize sua lista de compras</span>
                </div>
              </div>
            </li>
          </ul>
        </router-link>

      </template>
    </Steps>
    <router-view v-slot="{Component}" custom :formData="formObject" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>

import InventoryService from "@/service/InventoryService";
import moment from "moment";

export default {
  name: "despesa",
  created() {
    this.inventoryService = new InventoryService();
    this.moment = moment;
  },
  async mounted() {
    await this.loadEdit();
  },
  data() {
    return {
      items: [{
        label: 'Criar Despesa',
        to: '/despesa/step1',
        id: 1,
        isActive: false,
        isExactActive: false
      },
        {
          id: 2,
          label: 'Produtos',
          to: '/despesa/step2',
          isActive: false,
          isExactActive: false
        },
        {
          id: 3,
          label: 'Resumo',
          to: '/despesa/step3',
          isActive: false,
          isExactActive: false
        }],
      formObject: {},
      inventoryService: null,
      isActive: false,
      isExactActive: false
    }
  },
  methods: {

    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }
      if(event.despesaId) {
        this.$router.push(this.items[event.pageIndex + 1].to + '?id=' + event.despesaId);
      } else {
        this.$router.push(this.items[event.pageIndex + 1].to);
      }
    },
    prevPage(event) {
      if(event.despesaId) {
        this.$router.push(this.items[event.pageIndex - 1].to + '?id=' + event.despesaId);
      } else {
        this.$router.push(this.items[event.pageIndex - 1].to);
      }
    },
    async complete() {
      try {
        const response = await this.inventoryService.save(this.formObject);
        if (response.status === 200) {
          this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Despesa cadastrado com sucesso.', life: 3000});
          this.$router.push({name: 'desapesas-list'});
        }
      } catch(e) {
        this.$toast.add({
          severity: 'error',
          summary: 'ERRO',
          detail: 'Ocorreu um erro interno no servidor, tente novamente mais tarde.',
          life: 3000
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

</style>
