import api from '../configs/api';

export default class InventoryService {

	async findAll() {
		return api.get(`/admin/inventory?sort=dtPurchase,desc`);
	}

	async findById(id) {
		return api.get(`/admin/inventory/` + id);
	}

	async save(inventory) {
		return api.post(`/admin/inventory`, inventory);
	}

	async delete(id) {
		return api.delete(`/admin/inventory/` + id);
	}
}
